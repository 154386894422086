import Vue from 'vue';
import App from './App.vue';
import VModal from 'vue-js-modal';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VModal);
Vue.use(VueSimpleAlert);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
