<template>
  <div id="app" dir="rtl">
    <div class="bg-gray-100 min-h-screen text-gray-700 font-display">
      <divider/>

      <navbar/>

      <div class="container mx-auto px-8">
        <h1 class="text-6xl font-light text-center mt-20">
          <span class="px-2 py-1 bg-red-100 text-red-500">Our knowledge</span>,
          simply <br/>
          explained for your success</h1>

        <div class="py-16">
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-8" v-if="!loading">
            <course-card v-for="course in courses" :key="course.id" :course="course"/>
          </div>
          <div class="flex items-center justify-center" v-else>
            <div class="flex items-center justify-center bg-red-400 w-8 h-8 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" stroke="#fff">
                <g fill="none" fill-rule="evenodd" stroke-width="2">
                  <circle cx="22" cy="22" r="1">
                    <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1"
                             keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                    <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline"
                             keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                  </circle>
                  <circle cx="22" cy="22" r="1">
                    <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1"
                             keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                    <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline"
                             keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                  </circle>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CourseCard from "@/components/CourseCard";
import Divider from "@/components/Divider";
import axios from 'axios';
import {BASE_URL} from "@/Constants";
import Navbar from "@/components/Navbar";

export default {
  name: 'App',
  components: {Navbar, Divider, CourseCard},
  data() {
    return {
      courses: [],
      loading: true
    }
  },
  mounted() {
    axios.get(`${BASE_URL}/courses`)
        .then(response => {
          console.log(response);
          this.loading = false;
          this.courses = response.data;
        })
        .catch(error => console.log(error));
  }

}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
