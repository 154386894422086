<template>
  <div>
    <div class="bg-white overflow-hidden rounded shadow">
      <div>
        <img class="w-full"
             :src="course.imageUrl"
             alt="Courses"/>
      </div>

      <div class="p-6">

        <div class="mb-2">
          <span class="px-4 py-1 bg-red-400 text-white rounded-full">{{ course.price }} SDG</span>
        </div>

        <h2 class="text-2xl font-semibold">{{ course.title }}</h2>
        <p class="text-lg text-gray-600 mt-4">{{ course.description }}</p>

        <div class="text-center mt-4">
          <button
              @click="$modal.show(`course${course.id}`)"
              class="px-4 py-2 bg-red-500 text-white text-xl font-semibold w-full rounded"
          >Register
          </button>
        </div>
      </div>
    </div>

    <modal :name="`course${course.id}`" adaptive height="auto" >
      <div class="p-8">
        <h2 class="text-2xl font-semibold text-red-500">Register for the course</h2>
        <form @submit.prevent="submit">
          <div class="grid grid-cols-1 gap-6 mt-4">
            <div>
              <label class="text-gray-700">Full name</label>
              <input type="text"
                     v-model="form.name"
                     class="mt-2 bg-white text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:outline-none focus:ring" required/>
            </div>
            <div>
              <label class="text-gray-700">Email</label>
              <input type="text"
                     v-model="form.email"
                     class="mt-2 bg-white text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:outline-none focus:ring"/>
            </div>
            <div>
              <label class="text-gray-700">Phone number</label>
              <input type="text"
                     v-model="form.phone"
                     class="mt-2 bg-white text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:outline-none focus:ring" required/>
            </div>
          </div>

          <div class="mt-8">
            <button
                class="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-400 focus:outline-none focus:bg-red-400 w-full">
              Register
            </button>
          </div>
        </form>
      </div>
    </modal>

  </div>
</template>

<script>
import axios from "axios";
import {BASE_URL} from "@/Constants";

export default {
  props: ["course"],
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: ""
      }
    }
  },
  methods: {
    submit() {
      axios.post(`${BASE_URL}/courses/${this.course.id}/applications`, this.form)
      .then(response => {
        console.log(response);
        this.$modal.hide(`course${this.course.id}`)
        this.$fire({
          title: "Your application is submitted",
          text: "We will get back to you sooner than later",
          type: "success",
        }).then(r => {
          console.log(r.value);
        });
      })
      .catch(error => {
        console.log(error);
        this.$fire({
          title: "Ops something went wrong!",
          text: "Please try again",
          type: "success",
        }).then(r => {
          console.log(r.value);
        });
      })
    }
  }

}
</script>

<style scoped>

</style>